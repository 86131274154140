<template>
  <v-treeview
    id="layoutImportTreeview"
    v-model="selection"
    v-if="layoutImportSelected.length > 0"
    :items="treeViewLayoutConfiguration"
    :transition="true"
    selection-type="independent"
    :search="treeviewSearch"
    :load-children="loadChildren"
    selected-color="primary"
    return-object>
    <template #prepend="{ item, selected }">
      <v-checkbox
        :input-value="selected"
        :color="loadCheckboxColor(item)"
        :on-icon="validateNode(item) ? '$checkboxIndeterminate' : '$checkboxOn'"
        @click="selectNode(item)"
      />
    </template>
  </v-treeview>
</template>

<script>
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import { el } from 'date-fns/locale';

export default {
  name: "LayoutImportTreeView",

  data: () => ({
    selection: [],
  }),

  props: {
    treeViewLayoutConfiguration: {
      default: () => []
    },
    layoutImportSelected: {
      default: () => []
    },
    treeviewSearch: {
      type: String,
      default: ''
    },
    treeViewMounted: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    selection: {
      handler(newValue) {
        if (!this.treeViewMounted) return;
        if (newValue) {
          const selectionEmit = newValue
            .filter(item => ['insuranceCarrier', 'financialGroup', 'contract', 'subContract'].includes(item.type));

          this.$emit('selection', selectionEmit);
        }
      }
    },

    treeViewMounted: {
      async handler(newValue) {
        if (!newValue) return;

        if (!this.layoutImportSelected?.length) return;

        this.selection = [];

        const selected = this.layoutImportSelected[0];

        const processSelection = (ids, type) => ids.map(id => ({ id, type }));

        const handleCarrierSelection = () => {
          const carriersSelected = processSelection(selected.carrierIds, 'insuranceCarrier');
          carriersSelected.forEach(this.selectItem);
        };

        const handleFinancialGroupSelection = async () => {
          const financialGroupsSelected = processSelection(selected.financialGroupIds, 'financialGroup');
          financialGroupsSelected.forEach(this.selectItem);
          for (const financialGroup of financialGroupsSelected) {
            await this.loadChildren({ id: financialGroup.id, type: 'financialGroup' });
          }
        };

        const handleContractSelection = async () => {
          const contractsSelected = selected.contractIds.map(id => ({
            nodeId: id,
            id: `contract-${id}`,
            type: 'contract',
          }));

          for (let contract of contractsSelected) {
            const groupIndex = this.treeViewLayoutConfiguration[0].children.findIndex(financialGroup =>
              financialGroup.contractIds?.some(contractId => contractId === contract.nodeId)
            );
            if (groupIndex !== -1) {
              contract.financialGroupId = this.treeViewLayoutConfiguration[0].children[groupIndex].id;
              this.selectItem(contract);
              await this.loadChildren({
                id: contract.id,
                nodeId: contract.nodeId,
                financialGroupId: contract.financialGroupId,
                type: 'contract',
              });
            }
          }
        };

        const handleSubContractSelection = async () => {
          const subContractsSelected = selected.subcontractIds.map(id => ({
            nodeId: id,
            id: `subContract-${id}`,
            type: 'subContract',
          }));

          for (let subContract of subContractsSelected) {
            const groupIndex = this.treeViewLayoutConfiguration[0].children.findIndex(financialGroup =>
              financialGroup.children?.some(
                (contract) => contract.children.some(
                  (sub) => sub.subContractIds?.includes(subContract.nodeId)))
            );

            if (groupIndex !== -1) {
              const financialGroup = this.treeViewLayoutConfiguration[0].children[groupIndex];
              const contract = financialGroup.children[0].children.find(contract => contract.subContractIds?.includes(subContract.nodeId));

              subContract.financialGroupId = financialGroup.id;
              subContract.contractId = contract.id;
              subContract.contractNodeId = contract.nodeId;

              this.selectItem(subContract);
            }
          }
        };

        if (selected.carrierIds?.length) handleCarrierSelection();
        if (selected.financialGroupIds?.length) await handleFinancialGroupSelection();
        if (selected.contractIds?.length) await handleContractSelection();
        if (selected.subcontractIds?.length) await handleSubContractSelection();

        this.$emit('treeViewMounted', true);
    }

    },

  },

  mixins: [
    ContractsMixin,
    FinancialGroupsMixin,
    InsuranceCarriersMixin,
  ],

  methods: {
    // Buscar cor do checkbox por tipo
    loadCheckboxColor(node) {
      if (['financialGroups', 'insuranceCarriers'].includes(node.type)) return '#63C754';

      if (['contract', 'subContract'].includes(node.type)) return '#9FA9B9';

      if (['financialGroup', 'contracts', 'subContracts'].includes(node.type)) return '#3E4959';

      return 'primary';
    },

    // Buscar Contratos ou SubContratos
    async loadChildren({ id, nodeId, financialGroupId, type }) {
      if (
        !!this.treeViewLayoutConfiguration && this.treeViewLayoutConfiguration.length > 0
        && this.treeViewLayoutConfiguration[0].children.length > 0)
      switch(type) {
        case 'financialGroup':
          await this.loadFinancialGroupContracts(id);

          break;

        case 'contract':
          await this.loadContractSubContracts(nodeId, financialGroupId, id);

          break;
      }
    },

    // Buscar o indice de um Grupo Financeiro
    loadFinancialGroupIndex(id) {
      return this.treeViewLayoutConfiguration[0].children.findIndex(
        (financialGroup) => financialGroup.id === id && financialGroup.type === 'financialGroup'
      );
    },

    // Buscar Contratos a partir do Grupo Financeiro
    async loadFinancialGroupContracts(financialGroupId) {
      await this.getContractsByFinancialGroupIdVerifyRhProtegido(
        financialGroupId,
        this.isRHProtegido
      );

      const financialGroupIndex = this.loadFinancialGroupIndex(financialGroupId);

      if (this.contracts.length > 0) {
        const contracts = this.contracts.sort(
          (a, b) => (a.name > b.name ? 1 : -1));

        this.treeViewLayoutConfiguration[0].children[financialGroupIndex].contractIds = contracts.map(
          (contract) => contract.id);

        this.treeViewLayoutConfiguration[0].children[financialGroupIndex].children = [{
          id: `financialGroup-${this.treeViewLayoutConfiguration[0].children[financialGroupIndex].id}-contracts`,
          financialGroupId: this.treeViewLayoutConfiguration[0].children[financialGroupIndex].id,
          name: `Todos os Contratos (${contracts.length})`,
          type: 'parentContracts',
          children: contracts.map(
            (contract) => ({
              id: `contract-${contract.id}`,
              nodeId: contract.id,
              financialGroupId: financialGroupId,
              name: contract.name,
              type: 'contract',
              children: [],
            })
          )
        }]
      }

    },

    // Buscar SubContratos a partir de um Contrato
    async loadContractSubContracts(contractId, financialGroupId, id) {
      await this.getSubContractsByContractId(contractId);

      const groupIndex = this.loadFinancialGroupIndex(financialGroupId);

      const contractIndex = this.treeViewLayoutConfiguration[0].children[groupIndex].children[0].children.findIndex(
        el => el.id === id && el.type === 'contract'
      );

      if (this.subContracts.length > 0) {
        const subContracts = this.subContracts.sort(
          (a, b) => (a.name > b.name ? 1 : -1));

        const financialGroup = this.treeViewLayoutConfiguration[0].children[groupIndex];
        const contract = this.treeViewLayoutConfiguration[0].children[groupIndex].children[0].children[contractIndex];

        this.treeViewLayoutConfiguration[0].children[groupIndex].children[0].children[contractIndex].subContractIds = subContracts.map(
          (subContract) => subContract.id);

        this.treeViewLayoutConfiguration[0].children[groupIndex].children[0].children[contractIndex].children = [{
          id: `financialGroup-${financialGroup.id}-contract-${contract.id}-subcontracts`,
          financialGroupId: financialGroup.id,
          contractId: contract.id,
          name: `Todos os Subcontratos (${subContracts.length})`,
          type: 'parentSubContracts',
          children: subContracts.map(
            (subContract) => ({
              id: `subContract-${subContract.id}`,
              nodeId: subContract.id,
              name: subContract.name,
              financialGroupId: financialGroup.id,
              contractId: contract.id,
              contractNodeId: contract.nodeId,
              type: 'subContract',
            })
          )
        }]
      }
    },

    // Função principal chamada no @click
    selectNode(item) {
      if (this.isNodeSelected(item)) {
        // Desmarcar item e dependentes
        this.deselectItem(item);
      } else {
        // Selecionar item e dependentes
        this.selectItem(item);
      }

      setTimeout(() => {
        this.$emit('putMapping');
      }, 1000)
    },

    // Função para verificar se o item já está selecionado
    isNodeSelected(item) {
      return this.selection.some(
        selected => selected.id === item.id && selected.type === item.type
      );
    },

    // Função de seleção via tipo do nó
    selectItem(item) {
      switch (item.type) {
        case "financialGroups":
          // Selecionar todos os grupos financeiros
          this.selectFinancialGroups(item);
          break;

        case 'insuranceCarriers':
          // Selecionar todas as Operadoras
          this.selectInsuranceCarriers(item);
          break;

        case "financialGroup":
          // Selecionar o grupo financeiro
          this.selectFinancialGroup(item);
          break;

        case "contract":
          // Selecionar o contrato, e o grupo financeiro
          this.selectContract(item);
          break;

        case "subContract":
          // Selecionar o subcontrato, contrato pai, e grupo financeiro
          this.selectSubContract(item);
          break;

        case 'insuranceCarrier':
          //Selecionar a Operadora
          this.selectInsuranceCarrier(item);
          break;

        case "parentContracts":
          // Selecionar todos os contratos
          this.selectAllContracts(item);
          break;

        case "parentSubContracts":
          // Selecionar todos os subcontratos
          this.selectAllSubContracts(item);
          break;
      }
    },

    // Função para desmarcar nó ja selecionado
    deselectItem(item) {
      switch (item.type) {
        case "financialGroups":
          // Desmarcar todos os grupos financeiros e dependentes
          this.selection = [];
          break;

        case 'insuranceCarriers':
          // Desmarcar todas as Operadoras
          this.selection = [];
          break;

        case "financialGroup":
          // Desmarcar um grupo financeiro e seus dependentes
          this.deselectFinancialGroup(item);
          break;

        case "contract":
          // Desmarcar um contrato e seus dependentes
          this.deselectContract(item);
          break;

        case "subContract":
          // Desmarcar um subcontrato e seus dependentes
          this.deselectSubContract(item);
          break;

        case 'insuranceCarrier':
          // Desmarcar Operadora
          this.deselectInsuranceCarrier(item);
          break;

        case "parentContracts":
          // Desmarcar todos os contratos e seus dependentes
          this.deselectAllContracts(item);
          break;

        case "parentSubContracts":
          // Desmarcar todos os subcontratos
          this.deselectAllSubContracts(item);
          break;
      }
    },

    // Função para selecionar todos os contratos de um grupo financeiro
    selectAllContracts(parentContracts) {
      const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
        el => el.id === parentContracts.financialGroupId && el.type === 'financialGroup'
      );

      if (!this.selection.some(el => el.id === financialGroup.id && el.type === 'financialGroup')) {
        this.addToSelection(financialGroup);
      };

      parentContracts.children.forEach(contract => this.addToSelection(contract));
      this.selectFinancialGroup(parentContracts); // Executa a lógica 2
    },

    // Função para selecionar todos os subcontratos de um contrato
    selectAllSubContracts(parentSubContracts) {
      const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
        el => el.id === parentSubContracts.financialGroupId && el.type === 'financialGroup'
      )

      const parentContracts = financialGroup.children[0];

      const contract = parentContracts.children.find(
        contract => contract.id === parentSubContracts.contractId && contract.type === 'contract');

      if (!this.selection.some(el => el.id === contract.id && el.type === 'contract')) {
        this.addToSelection(contract);
      };

      parentSubContracts.children.forEach(subContract => this.addToSelection(subContract));
      this.selectContract(parentSubContracts); // Executa a lógica 3 e 2
    },

    // Função para desmarcar todos os contratos de um grupo financeiro
    deselectAllContracts(parentContracts) {
      parentContracts.children.forEach(contract => this.removeFromSelection(contract));

      this.deselectFinancialGroup(parentContracts);
    },

    // Função para desmarcar todos os subcontratos de um contrato
    deselectAllSubContracts(parentSubContracts) {
      parentSubContracts.children.forEach(
        (subContract) => this.removeFromSelection(subContract));

      this.deselectContract(parentSubContracts);
    },

    // Função para remover um item da seleção
    removeFromSelection(item) {
      this.selection = this.selection.filter(
        (selected) => !(selected.id === item.id && selected.type === item.type));
    },

    // Função para selecionar todos os grupos financeiros
    selectFinancialGroups(parentItem) {
      this.addToSelection(parentItem);

      parentItem.children.forEach(
        (child) => this.addToSelection(child));
    },

    // Função para selecionar um grupo financeiro e o item "Selecionar todos os grupos financeiros"
    selectFinancialGroup(financialGroup) {
      const parentGroup = this.treeViewLayoutConfiguration.find(
        (group) => group.type === "financialGroups"
      );

      this.addToSelection(parentGroup);

      this.addToSelection(financialGroup);
    },

    // Função para selecionar um contrato e seus itens relacionados
    selectContract(contract) {
      const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
        (group) => group.id === contract.financialGroupId && group.type === 'financialGroup'
      );

      if (!this.selection.some((node) => node.id === financialGroup.id && node.type === 'financialGroup')) {
        this.addToSelection(financialGroup);
      };

      const parentContracts = financialGroup.children[0];

      if (!!parentContracts) this.addToSelection(parentContracts);

      if (!this.selection.some((node) => node.id === contract.id && node.type === 'contract')) {
        this.addToSelection(contract);
      }

      this.selectFinancialGroup(parentContracts);
    },

    // Função para selecionar subcontratos e itens relacionados
    selectSubContract(subContract) {
      const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
        (group) => group.id === subContract.financialGroupId && group.type === 'financialGroup'
      );

      if (!this.selection.some((node) => node.id === financialGroup.id && node.type === 'financialGroup')) {
        this.addToSelection(financialGroup);
      };

      const parentContracts = financialGroup.children[0];

      if (!this.selection.some((node) => node.id === parentContracts.id && node.type === 'parentContracts')) {
        this.addToSelection(parentContracts);
      };

      const contract = parentContracts.children.find(
        (el) => el.id === subContract.contractId && el.type === 'contract');

      if (!this.selection.some((el) => el.id === contract.id && el.type === 'contract')) {
        this.addToSelection(contract);
      };

      const parentSubContracts = contract.children[0];

      if (!this.selection.some((el) => el.id === parentSubContracts.id && el.type === 'parentSubContracts')) {
        this.addToSelection(parentSubContracts);
      };

      if (!this.selection.some((node) => node.id === subContract.id && node.type === 'subContract')) {
        this.addToSelection(subContract);
      }

      this.selectContract(parentSubContracts);
    },

    // Função para selecionar todas as operadoras
    selectInsuranceCarriers(parentItem) {
      this.addToSelection(parentItem);

      parentItem.children.forEach(
        (child) => this.addToSelection(child));
    },

    // Função para selecionar Operadora
    selectInsuranceCarrier(insuranceCarrier) {
      const parentCarrier = this.treeViewLayoutConfiguration[0];

      this.addToSelection(parentCarrier);

      this.addToSelection(insuranceCarrier);
    },

    // Função para adicionar item à seleção
    addToSelection(item) {
      if (!!item && !this.isNodeSelected(item)) this.selection.push(item);
    },

    // Função para desmarcar os Grupos Financeiros e seus descendentes
    deselectFinancialGroup(financialGroup) {
      this.selection = this.selection.filter(
        (selected) => selected.id !== financialGroup.id && !this.isDescendant(financialGroup, selected));

      // Caso só tenha apenas o 'Selecionar todos os Grupos Financeiros' marcado, ele então é removido
      if (this.selection.length === 1) {
        this.selection = [];
      }
    },

    // Função para desmarcar Contrato
    deselectContract(contract) {
      this.selection = this.selection.filter(
        (selected) => selected.id !== contract.id && !this.isDescendant(contract, selected)
      );

      if (!this.selection.some((node) => node.financialGroupId === contract.financialGroupId && node.type === 'contract')) {
        const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
          el => el.id === contract.financialGroupId && el.type === 'financialGroup'
        );

        const parentContracts = financialGroup.children[0];

        this.selection = this.selection.filter(
          (selected) => selected.id !== parentContracts.id && !this.isDescendant(parentContracts, selected)
        );
      }
    },

    // Função para desmarcar SubContrato
    deselectSubContract(subContract) {
      this.selection = this.selection.filter(
        (selected) => selected.id !== subContract.id && !this.isDescendant(subContract, selected));

      if (!this.selection.some((some) => some.contractId === subContract.contractId && some.type === 'subContract')) {
        const financialGroup = this.treeViewLayoutConfiguration[0].children.find(
          (el) => el.id === subContract.financialGroupId
        );

        const parentContracts = financialGroup.children[0];

        const contract = parentContracts.children.find(
          (contract) => contract.id === subContract.contractId);

        const parentSubContracts = contract.children[0];


        this.selection = this.selection.filter(
          (selected) => selected.id !== parentSubContracts.id && !this.isDescendant(parentSubContracts, selected)
        );
      }
    },

    // Função para desmarcar Operadora
    deselectInsuranceCarrier(insuranceCarrier) {
      this.selection = this.selection.filter(
        (selected) => selected.id !== insuranceCarrier.id);

      // Caso só tenha apenas o 'Selecionar todas as Operadoras' marcado, ele então é removido
      if (this.selection.length === 1) {
        this.selection = [];
      }
    },

    // Função para verificar se um item é descendente de outro
    isDescendant(parent, child) {
      if (parent.children) {
        return parent.children.some(descendant => {
          return descendant.id === child.id || this.isDescendant(descendant, child);
        });
      }
      return false;
    },

    // Função para validar se todos os filhos de um nó estão marcados
    validateNode(node) {
      if (this.treeViewLayoutConfiguration.length === 0) return false;

      if (this.treeViewLayoutConfiguration[0].children.length === 0) return false;

      if (node && node.children && node.children.length > 0) {
        switch(node.type) {
          case "financialGroups":
            return this.validateNodeFinancialGroups(node);

          case "insuranceCarriers":
            return this.validateNodeInsuranceCarriers(node);

          case "financialGroup":
            return this.validateNodeFinancialGroup(node);

          case "parentContracts":
            return this.validateNodeParentContracts(node);

          case "contract":
            return this.validateNodeContract(node);

          case "parentSubContracts":
            return this.validateNodeParentSubContracts(node);
        }
      }

      return false;
    },

    // Função para validar os Grupos Financeiros
    validateNodeFinancialGroups(node) {
      const financialGroupsLength = this.treeViewLayoutConfiguration[0].children.length;

      const financialGroupsSelectedLength = this.selection.filter((el) => el.type === 'financialGroup').length;

      return financialGroupsSelectedLength > 0 && financialGroupsLength !== financialGroupsSelectedLength;
    },

    // Função para validar as Operadoras
    validateNodeInsuranceCarriers(node) {
      const insuranceCarriersLength = this.treeViewLayoutConfiguration[0].children.length;

      const insuranceCarriersSelectedLength = this.selection.filter((el) => el.type === 'insuranceCarrier').length;

      return insuranceCarriersSelectedLength > 0 && insuranceCarriersLength !== insuranceCarriersSelectedLength;
    },

    // Função para validar o Grupo Financeiro selecionado
    validateNodeFinancialGroup(node) {
      const allContractsLength = node.children[0].children.length;

      const contractsSelectedLength = this.selection.filter(el => el.financialGroupId === node.id && el.type === 'contract').length;

      return contractsSelectedLength > 0 && allContractsLength !== contractsSelectedLength;
    },

    // Função para validar a seleção de todos os Contratos
    validateNodeParentContracts(node) {
      const allContractsLength = node.children.length;

      const contractsSelectedLength = this.selection.filter(el => el.financialGroupId === node.children[0].financialGroupId && el.type === 'contract').length;

      return contractsSelectedLength > 0 && allContractsLength !== contractsSelectedLength;
    },

    // Função para validar o Contrato selecionado
    validateNodeContract(node) {
      const allSubContractsLength = node.subContractIds.length;

      const subContractsSelectedLength = this.selection.filter(el => el.contractId === node.id  && el.type === 'subContract').length;

      return subContractsSelectedLength > 0 && allSubContractsLength !== subContractsSelectedLength;
    },

    // Função para validar o SubContrato selecionado
    validateNodeParentSubContracts(node) {
      const allSubContractsLength = node.children.length;

      const subContractsSelectedLength = this.selection.filter(el => el.contractId === node.contractId  && el.type === 'subContract').length;

      return subContractsSelectedLength > 0 && allSubContractsLength !== subContractsSelectedLength;
    },
  },
}
</script>

<style>
#layoutImportTreeview
  > .v-treeview-node
    > .v-treeview-node__children
      > .v-treeview-node {
  border: 2px solid #9FA9B8;
  margin: 10px 0;
  border-radius: 10px;
}
</style>
